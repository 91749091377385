<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title primary-title>
        <h5 class="headline logo_text">
          <i class="fa fa-map-marker logo_icon" />&nbsp;&nbsp; New Marker
        </h5>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-alert :value="errors.items.length > 0" type="error">
          <div v-for="error in errors.items" :key="error.name">
            {{ error.msg }} <br />
          </div>
        </v-alert>

        <v-stepper v-model="stepper" class="elevation-0">
          <v-stepper-header>
            <v-stepper-step
              :complete="stepper > 1"
              :rules="[() => infoValid]"
              step="1"
              editable
              >Basic Info</v-stepper-step
            >
            <v-divider />
            <v-stepper-step
              :complete="stepper > 2"
              :rules="[() => locationValid]"
              step="2"
              editable
              >Location</v-stepper-step
            >
            <v-divider />
            <v-stepper-step :rules="[() => extraValid]" step="3" editable
              >Extra</v-stepper-step
            >
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <marker-info v-model="marker" />
            </v-stepper-content>
            <v-stepper-content step="2">
              <marker-location
                v-model="location"
                v-validate.disable="'location:' + token"
                data-vv-scope="location"
              />
            </v-stepper-content>
            <v-stepper-content step="3">
              <marker-extra v-model="extra" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn v-if="stepper == 2" text @click="stepper = 1"> Back </v-btn>
        <v-btn v-else-if="stepper == 3" text @click="stepper = 2"> Back </v-btn>

        <v-btn v-if="stepper == 1" text @click="stepper = 2"> Continue </v-btn>
        <v-btn v-else-if="stepper == 2" text @click="stepper = 3">
          Continue
        </v-btn>
        <v-btn v-else-if="stepper == 3" text @click="setMarker"> Add </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import markerLocation from "./Location.vue";
import markerInfo from "./Info.vue";
import markerExtra from "./Extra.vue";
import { verifyLocation } from "./Rules/verifyLocationRule";

export default {
  name: "AddMarker",
  components: {
    markerLocation,
    markerInfo,
    markerExtra
  },
  props: {
    lat: {
      type: String,
      default: null
    },
    long: {
      type: String,
      default: null
    },
    copyId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      marker: {
        icon: "",
        pin: "",
        name: "",
        data: {
          range: {
            start: "",
            end: ""
          },
          value: ""
        }
      },
      location: {
        lat: "",
        long: "",
        city: "",
        state: "",
        country: ""
      },
      extra: {
        addInfo: "",
        link: {
          url: "",
          title: ""
        }
      },
      defaultLocation: null,
      dialog: true,
      stepper: 1,
      token: ""
    };
  },
  computed: {
    ...mapGetters(["currMap", "user"]),
    infoValid() {
      return !this.errors.items.some(item => item.scope === "marker");
    },
    locationValid() {
      return !this.errors.items.some(item => item.scope === "location");
    },
    extraValid() {
      return !this.errors.items.some(item => item.scope === "extra");
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$emit("close");
      }
    }
  },
  async mounted() {
    this.token = await this.user.getIdToken(true);
    if (this.lat && this.long) {
      this.location.lat = this.lat;
      this.location.long = this.long;
    }

    if (this.copyId) {
      const marker = Object.assign(
        {},
        this.currMap.markers.find(marker => marker.id === this.copyId)
      );

      for (const prop in this.marker) {
        this.marker[prop] = marker[prop] ? marker[prop] : "";
      }
      for (const prop in this.location) {
        this.location[prop] = marker[prop] ? marker[prop] : "";
      }
      for (const prop in this.extra) {
        this.extra[prop] = marker[prop] ? marker[prop] : this.extra[prop];
      }
    }
  },
  created() {
    this.$validator.extend("location", verifyLocation, ["token"]);
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  methods: {
    async setMarker() {
      const validMarker = await this.$validator.validateAll("marker");
      const validExtra = await this.$validator.validateAll("extra");
      if (validMarker && validExtra) {
        // check if changed
        const validLocation = this.locationChanged()
          ? await this.$validator.validateAll("location")
          : true;
        if (validLocation) {
          await this.dispatchSetMarker();
          this.dialog = false;
        }
      }
    },

    async dispatchSetMarker() {
      let marker = Object.assign({}, this.marker, this.extra);
      marker.location = Object.assign({}, this.location);
      delete marker.action;
      await this.$store.dispatch("addMapRecord", marker);
    },

    locationChanged() {
      if (!this.defaultLocation) {
        return true;
      }

      for (const key in this.defaultLocation) {
        if (this.defaultLocation[key] !== this.location[key]) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>
<style scoped>
.v-stepper,
.v-stepper__header {
  -webkit-box-shadow: 0px 0px;
  box-shadow: 0px 0px;
}
</style>
